<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="tituloModal"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="ActualizaRubro"
    >
      <CRow class="mt-2">
        <CCol sm="12">        
              <CInput
                :label= "`${$t('label.heading')} (ES)`"
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
                v-model.trim="$v.HeadingNameEs.$model"
                v-uppercase
                :placeholder="`${$t('label.heading')} (ES)`"
                :invalid-feedback="errorMessage($v.HeadingNameEs)"
                maxlength="250"
                required
                :is-valid="hasError($v.HeadingNameEs)"
              />
        </CCol>
        <CCol sm="12">        
              <CInput
                :label= "`${$t('label.heading')} (EN)`"
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
                :placeholder="`${$t('label.heading')} (EN)`"
                v-model.trim="$v.HeadingNameEn.$model"
                v-uppercase
                :invalid-feedback="errorMessage($v.HeadingNameEn)"
                maxlength="250"
                required
                :is-valid="hasError($v.HeadingNameEn)"
              />
        </CCol>
        <CCol sm="12" v-if="actualizar" >

          <CSelect
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
            addLabelClasses="required text-right"
            v-model="Status"
            :value.sync="Status"
            :is-valid="statusSelectColor"
            :options="selectOptions"
            required
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton outline color="add" @click="changeStatus">
          <CIcon name="checkAlt"/>&nbsp;
          {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="ActualizaRubro=false">
          <CIcon name="x"/>&nbsp;
         {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import RubroValidations from '@/_validations/rubro/rubroValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import {alertPropertiesHelpers} from '@/_helpers/funciones';

//DATA
function data () {
  return {
    //MODELO
    HeadingId: '',
    HeadingNameEs: '',
    HeadingNameEn: '',
    Status: 1,
    //VARIABLES
    selectOptions: [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ],
    tituloModal: '',
    ActualizaRubro: false,
    Loading: false,   
    actualizar: false,   
  }
}

function refreshComponent() {
  this.HeadingId='';
  this.HeadingNameEs= '';
  this.HeadingNameEn= '';
  this.Status=1;
  this.$nextTick(() => { this.$v.$reset() })
}

function changeStatus() {
  if (this.Status===0) {
    this.$swal.fire(
       alertPropertiesHelpers(this,{
          text:`${this.$t('label.changeStatusQuestion')}`
        })
      ).then((result) => {
      if (result.isConfirmed) {
        this.submit();
      }
    });  
  } else this.submit();
}

function submit() {
  try {
    this.$v.$touch();
    if (this.$v.$invalid) {
        throw this.$t('label.errorsPleaseCheck');
    }

    if (this.$v.$invalid) return false;
    this.Loading = true;
    let res = [];
    let metodo = '';
    let ruta = '';
    let HeadingJson = [];
    
    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.actualizar) {
      HeadingJson = {
        HeadingId: this.HeadingId,
        HeadingNameEs: this.HeadingNameEs,
        HeadingNameEn: this.HeadingNameEn,
        Status: this.Status
      };
      metodo = 'PUT';
      ruta = "Heading-update";
    } else {
      HeadingJson = {
        HeadingNameEs: this.HeadingNameEs,
        HeadingNameEn: this.HeadingNameEn
      };
      metodo = 'POST';
      ruta = "Heading-insert";
    }
    
    this.$http.ejecutar(metodo, ruta, HeadingJson, { root: 'HeadingJson' })
    .then(response => {
      res = [...response.data.data];
      this.refreshComponent();
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: res[0].Response,
        type: "success"
      });
      this.Loading= false;
      this.$emit('child-refresh', true);
      this.ActualizaRubro = false;
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading= false;
    });
  } catch (e) {
    this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: e,
        type: "error"
      });
  }
}


async function setHeadingForm() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let title = this.modal['HeadingName'+_lang]
  this.actualizar     = true;
  this.tituloModal    =  this.$t('label.edit') + this.$t('label.heading') + ": "+title;
  this.HeadingId      = this.modal.HeadingId;
  this.HeadingNameEs    = this.modal.HeadingNameEs;
  this.HeadingNameEn    = this.modal.HeadingNameEn;
  this.Status         = this.modal.FgActHeading ? 1 : 0;
  this.selectOptions[this.Status];
}

export default {
  name: 'heading-modal',
  mixins: [General],
  data,
  props: {
    modal: null
  },
  directives: UpperCase,
  validations: RubroValidations,
  watch: {
    modal: async function () {
      if (this.modal) {
        this.refreshComponent();
        this.ActualizaRubro = true;
        if (this.modal==true) {
          this.actualizar = false;
          this.tituloModal = this.$t('label.nuevo') + this.$t('label.heading');
        } else {
          await this.setHeadingForm();
          this.$v.$touch();
          /*this.actualizar     = true;
          this.tituloModal    =  this.$t('label.edit') + this.$t('label.heading') + ": "+this.modal.HeadingName;
          this.HeadingId      = this.modal.HeadingId;
          this.HeadingName    = this.modal.HeadingName;
          this.Status         = this.modal.FgActHeading ? 1 : 0;
          this.selectOptions[this.Status];*/
        }

        this.$emit('cerrarModal');
      }
    },
  },
  methods: {
    refreshComponent,
    changeStatus,
    submit,
    setHeadingForm,
  },
}
</script>
<style scoped>
.alto {
  height: 200px;
}
</style>